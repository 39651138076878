<template>
<div>
    <Toolbar>
        <template #start>
            Hardware in Project:&nbsp;<em>{{ pName }}/{{state.hardwareName }}</em> Hansi
        </template>
        <template #end>
            <ProgressSpinner v-if="state.downloadCode" style="width:2.8em;height:2.8em" />
            <span v-if="aState.user.groups.PROJECT_ADMIN">
                <Button style="margin-left: 1em" icon="fa-regular fa-copy" label="Copy to" class="p-button-primary"
                    @click="showCopyDialog"
                    iconPos="left"
                    />
                <Dropdown
                    :options="otherProjects"
                    v-model="state.destProject"
                    optionLabel="label" optionValue="value"
                    style="width: 15em"
                />
            </span>
            <Button style="margin-left: 1em" icon="fa-regular fa-code" label="generate Code" class="p-button-success"
                @click="generateCode"
                iconPos="left"
                :disabled="state.downloadCode"
                v-if="aState.user.groups.PROJECT_CHANGE_VALUES"
                />
        </template>
    </Toolbar>
    <TabView v-model:activeIndex="state.activeTab">
        <TabPanel  v-if="aState.user.groups.PROJECT_CHANGE_VALUES || aState.user.groups.MODEL_EDIT_PROJECT_SETTINGS">
            <template #header>
                <span>Modules</span>
                <Button icon="fa-regular fa-list-alt" class="p-button-sm p-button-text" :disabled="true" />
            </template>
            <ProjectInstanceList
                :puh-id="id"
                :models="state.projectModels"
                :change-instance="state.changeInstance"
                @edit-instance="editInstance"
                />
        </TabPanel>
        <TabPanel v-for="{object, spec} in state.instances" :key="object.pk" >
            <template #header>
                <span>{{ spec.name }}: {{object.name}}</span>
                <span class="p-buttonset">
                    <Button icon="fa-regular fa-refresh" class="p-button-sm p-button-text" @click.stop="refreshTab(object)" />
                    <Button icon="fa-regular fa-times"   class="p-button-sm p-button-text p-button-danger" @click.stop="closeTab(object)" />
                </span>
            </template>
            <InstanceEdit v-if="spec.module != 3"
                :object="object" :spec="spec" :project-id="id"
                @cancel="closeTab(object)"
                @update-instance="updateInstance"
                />
            <ProjectSettings v-else
                :puh="id"
                :object="object"
                :modules="state.projectModels"
                @cancel="closeTab(object)"
            />
        </TabPanel>
    </TabView>
    <Dialog v-model:visible="state.showCopyVisible" style="width: 50vw">
        <template #header>
            Copy hardware {{ lName }}({{ state.newHW.hwName }}) of project {{ pName }} to {{ state.newHW.dstProject }}
        </template>
        <div class="p-field-checkbox">
            <label for="new_name" style="width: 10rem">Name</label>
            <InputText id="new_name" v-model="state.newHW.name" />
        </div>
        <div class="p-field-checkbox">
            <label for="new_address" style="width: 10rem">Address</label>
            <InputText id="new_address" v-model="state.newHW.address" />
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" @click="state.showCopyVisible = false" class="p-button-text"/>
            <Button label="Copy" icon="pi pi-check" autofocus @click="copyHardware"
            />
        </template>
        </Dialog>
</div>
</template>

<script>
import {reactive, computed, onBeforeMount }     from "vue"
import { onBeforeRouteUpdate  }                 from 'vue-router'
import Dialog                       from 'primevue/dialog';
import InputText                    from 'primevue/inputtext';
import TabView                      from 'primevue/tabview'
import Toolbar                      from 'primevue/toolbar'
import TabPanel                     from 'primevue/tabpanel'
import auth                         from "@/store/auth"
import ProjectInstanceList          from "@/components/isew/ProjectInstanceList"
import InstanceEdit                 from "@/components/isew/InstanceEdit"
import ProjectSettings              from "@/components/isew/ProjectSettings"
import {projects, projectState }    from "@/api/projects"
import { useToast }                 from "primevue/usetoast";
import mstore                       from "@/store/models"
import ProgressSpinner              from 'primevue/progressspinner'
import Dropdown                     from 'primevue/dropdown'

export default {
    props: {
        id:             {type:Number,  required: true},
        pName:          {type: String, required: true},
        lName:          {type: String, required: true},
        hardwareId:     {type: Number, required: true},
    },
    setup (props) {
        const aState = auth.getAuthState ()
        const toast = useToast()
        const state = reactive ({hardwareName: "", loading: true,
            projectModels: [],
            editingRows: [],
            instances: [],
            activeTab: 0,
            changeInstance: null,
            downloadCode: false,
            destProject: 0,
            showCopyVisible: false,
            newHW: {name: "", address:0, hwName:"", dstProject: ""}
            })
        let   savedObjects = {}
        const _update = (({data}) => {
            if (data.status) {
                state.projectModels = []
                data.objects.forEach(mod => {
                    mod.module_name = mod.module [1]
                    state.projectModels.push (mod.module)
                });
                state.hardwareName  = data.objects [0].hardware [1]
                if (state.hardwareName != props.lName)
                    state.hardwareName += "(" + props.lName + ")"
                state.loading       = false
            }
        })
        projects.loadHardwareModules (props.hardwareId).then (_update)
        onBeforeRouteUpdate(async (to) => {
            state.loading = true
            const r = await projects.loadHardwareModules (to.params.hardwareId)
            _update (r)
        })
        let allModules = []
        const getIdx = (list, cmp, fct) => {
            if (fct === undefined) fct = (e) => e.id
            for (let i=0; i < list.length; i++) {
                if (fct (list [i]) == cmp) {
                    return i
                }
            }
            return -1
        }
        const mSort = (a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0 }
        projects.allModules ().then (({data}) => {
            allModules = data.objects
            allModules.sort (mSort)
        })
        const rowEditInit = (evt) => {
            const obj = evt.data
            savedObjects [obj.id] = {...obj}
        }
        const rowEditDone = (evt, save) => {
            const obj = evt.data
            if (!save) {
                let idx = getIdx (state.modules, obj.id)
                state.modules [idx] = savedObjects [obj.id]
            }
            delete savedObjects [obj.id]
        }
        const getModel = async (modelName) => {
            if (modelName === undefined) modelName = "Module_Instance"
            state.loading = true
            mstore.getModelInner (modelName).then (({model, error}) => {
                if (model)
                    state.model = model
                else if (error) {
                    state.message = {kind: "error", message: error}
                }
            })
        }
        onBeforeMount (getModel)
        onBeforeRouteUpdate(async () => {
            await getModel ("Module_Instance")
        })
        const projectModels = computed (() => {
            let result =[]
            for (let mip of state.modules) {
                if (mip.user_editable || mip.is_singleton)
                    result.push (mip.module)
            }
            result = result.sort ((a, b) => a[1].localeCompare (b[1]))
            return result
        })
        const editInstance = ({object, spec}) => {
            let idx = getIdx (state.instances, object.pk, (e) => e.object.pk)
            if (idx < 0) {
                idx = state.instances.length
                state.instances.push ({object, spec})
            }
            state.activeTab = 1 + idx
        }
        const closeTab = (object) => {
            let idx = getIdx (state.instances, object.pk, (e) => e.object.pk)
            const tIdx = idx + 1
            if (state.activeTab == tIdx) {
                state.activeTab -= 1
            } else if (state.activeTab > tIdx) {
                state.activeTab -= 1
            }
            state.instances.splice (idx, 1)
        }
        const refreshTab = (object) => {
            getIdx (state.instances, object.pk, (e) => e.object.pk)
        }
        const updateInstance = ({pk, name}) => {
            state.changeInstance = {pk, name}
        }
        const generateCode = async () => {
            state.downloadCode = true
            try {
                await projects.generateCode (props.hardwareId, props.id)
                toast.add({severity:'info', summary: 'Code Generation successfull', life: 3000});
                state.downloadCode = false
            } catch (err) {
                toast.add({severity:'error', summary: 'Code Generation failure', detail:err.toString (), life: 10000});
                state.downloadCode = false
            }
        }
        const downloadProjectSettings = async () => {
            state.downloadCode = true
            try {
                await projects.downloadProjectSettings (props.id)
                toast.add({severity:'info', summary: 'Project Settings Download successfull', life: 3000});
                state.downloadCode = false
            } catch (err) {
                toast.add({severity:'error', summary: 'Project Settings Download failure', detail:err.toString (), life: 10000});
                state.downloadCode = false
            }
        }
        const showCopyDialog = async () => {
            state.showCopyVisible = true
            for (const p of projectState.projects)  {
                for (const puh of p.hardwares) {
                    if (puh.id == props.id) {
                        state.newHW.hwName  = puh.hardware [1]
                        state.newHW.name    = puh.name
                        state.newHW.address = puh.address
                    }
                }
                if (p.id == state.destProject)
                    state.newHW.dstProject = p.name
            }
        }
        const copyHardware = async () => {
            const r = await projects.copyHardwareToProject (props.id, state.destProject, state.newHW.name, state.newHW.address)
            if (r.status) {
                toast.add({severity:'success',
                    summary: `${r.instance_count} Instances copied with ${r.values_count} values`,
                    life: 3000});
                state.showCopyVisible = false
            } else {
                toast.add({severity:'error', summary: 'Hardware copy failed'});
            }
        }
        const otherProjects = computed (() => {
            let result = []
            projectState.projects.forEach ((p) => {
                result.push ({label: p.name, value: p.id})
            })
            return result
        })
        return {aState, state, rowEditDone, rowEditInit,
            generateCode, updateInstance, downloadProjectSettings,
            projectModels, editInstance, refreshTab, closeTab,
                otherProjects, copyHardware, showCopyDialog}
    },
    components: {TabView, TabPanel, ProjectInstanceList, InstanceEdit,
        Toolbar, ProgressSpinner, ProjectSettings, Dropdown,
        Dialog, InputText
        }
}
</script>

<style>
</style>
