<template>
	<div class="layout-static" :class="containerClass" @click="onWrapperClick">
		<AppTopBar @menu-toggle="onMenuToggle" />

        <transition name="layout-sidebar">
            <div class="layout-sidebar layout-sidebar-dark" @click="onSidebarClick" v-show="isSidebarVisible()">
                <div class="layout-logo">
                    <router-link to="/">
                        <img alt="Logo" src="@/assets/logo_small.png" />
                    </router-link>
                </div>

                <AppProfile />
                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
            </div>
        </transition>

		<div class="layout-main">
			<router-view />
		</div>

		<AppFooter /><Toast />
	</div>
</template>

<script>
import {reactive, computed, onMounted }   from "vue"
import AppTopBar    from "@/components/main/AppTopbar.vue"
import AppProfile   from "@/components/main/AppProfile.vue"
import AppMenu      from "@/components/main/AppMenu.vue"
import AppFooter    from "@/components/main/AppFooter.vue"
import Toast        from 'primevue/toast'
import auth         from "@/store/auth"
import {projects, projectState}     from "@/api/projects"

export default {
    setup () {
        const state = reactive ({
            menuClick:              false,
            staticMenuInactive:     false,
            mobileMenuActive:       false,
        })
        const aState = auth.getAuthState ()
        const editOrder = () => {
            return aState.user.groups.MODEL_EDIT_ORDER == true
        }
        const projectMenu = computed (() => {
            let result = []
            if (aState.user.groups.PROJECT_EDIT) {
                result.push ({label: "Project admin",   icon: "fa-duotone fa-truck-loading", route: "project-admin"})
                result.push ({label: "Add new project", icon: "fa-duotone fa-plus-circle", route: "project-new"})
            }
            projectState.projects.forEach (e => {
                let hardwares = []
                result.push ({label: e.name, icon: "fa-duotone fa-construction", items: hardwares, route : "project-detail", params: {id: e.id, modelName: "Project", asNew: "false"}})
                e.hardwares.forEach (e => {
                    let name = e.name
                    if (!name) name = e.hardware [1]
                    hardwares.push ({label: name, icon: "fa-duotone fa-microchip", route: "hardware-uses-project", params: {id: e.id, pName: e.project [1], lName: name, hardwareId: e.hardware [0]}})
                })
            })
            return result
        })
        const menu = reactive ([
            {label: "Dashboard", icon: "fa-duotone fa-home", route: "dashboard"},
            {
                label: "Modules", icon: "fa-duotone fa-sitemap", visible() {
                    return aState.user.groups.MODEL_EDIT_DESCRIPTION == true
                },
                items: [
                    {label: "Commands",     icon: "fa-duotone fa-newspaper", model: "Protocol_Command", visible: editOrder},
                    {label: "Status Codes", icon: "fa-duotone fa-newspaper", model: "Status_Code", visible: editOrder},
                    {label: "Features",     icon: "fa-duotone fa-newspaper", model: "Feature", visible: editOrder},
                    {seperator: true},
                    {label: "Base Class",   icon: "fa-duotone fa-user-robot", model: "Base_Module", visible: editOrder},
                    {label: "Var Type",     icon: "fa-duotone fa-newspaper", model: "Var_Type",    visible: editOrder},
                    {label: "Parameters",   icon: "fa-duotone fa-newspaper", model: "Parameter"},
                    {label: "Modules",      icon: "fa-duotone fa-newspaper", model: "Module"},
                    {seperator: true},
                    {label: "Hardware",     icon: "fa-duotone fa-newspaper", model: "Hardware"},
                    {seperator: true},
                    {label: "Hardware Type", icon: "fa-duotone fa-newspaper", model: "Hardware_Type"},
                    {label: "Software Type", icon: "fa-duotone fa-newspaper", model: "Software_Type"},
                    //{label: "Conditions",   icon: "fa-duotone fa-newspaper", model: "Condition"},
                    ]
            },
            {
                label: "Projects", icon:"fa-duotone fa-construction", visible() {
                    return aState.user.groups.PROJECT_CHANGE_VALUES == true
                        || aState.user.groups.MODEL_EDIT_PROJECT_SETTINGS == true
                },
                items: projectMenu
            },
            {
                label: "License Keys", icon:"fa-duotone fa-file-certificate", visible() {
                    return aState.user.groups.LICENCE_KEY_DOWNLOAD == true
                },
                items: [
                    {label: "Customers", icon: "fa-duotone fa-users",       route: "customer-tree"},
                ]
            },
            {
                label: "License Key Management", icon:"fa-duotone fa-file-certificate", visible() {
                    return aState.user.groups.LKM_ADMIN == true
                },
                items: [
                    {label: "Client",           icon: "fa-duotone fa-newspaper", model: "Client"},
                    {label: "Machine",          icon: "fa-duotone fa-newspaper", model: "Machine"},
                    {label: "Application",      icon: "fa-duotone fa-newspaper", model: "Application"},
                    {label: "Board Config",     icon: "fa-duotone fa-newspaper", model: "Board_Config"},
                    {label: "Box",              icon: "fa-duotone fa-newspaper", model: "Box"},
                    {label: "Board",            icon: "fa-duotone fa-newspaper", model: "Board"},
                    {label: "Purchase Order",   icon: "fa-duotone fa-newspaper", model: "Purchase_Order"},
                    {label: "Order Position",   icon: "fa-duotone fa-newspaper", model: "Order_Position"},
                    // {label: "",  icon: "fa-duotone fa-newspaper", model: ""},
                ]
            },
            {
                label: "Translation", icon:"fa-duotone fa-language", visible() {
                    return  (aState.user.groups.TRANSLATE_UPLOAD    == true)
                         || (aState.user.groups.TRANSLATE_TRANSLATE == true)
                         || (aState.user.groups.TRANSLATE_DOWNLOAD  == true)
                },
                items: [
                    {label: "Upload",    icon: "fa-duotone fa-file-arrow-up",       route: "translation-upload", visible() {
                        return  (aState.user.groups.TRANSLATE_UPLOAD    == true)
                    }},
                    {label: "Translate", icon: "fa-duotone fa-language",            route: "translation-translate", visible () {
                        return  (aState.user.groups.TRANSLATE_TRANSLATE    == true)
                    }},
                    {label: "Download",  icon: "fa-duotone fa-file-arrow-down",     route: "translation-download", visible() {
                        return  (aState.user.groups.TRANSLATE_DOWNLOAD    == true)
                    }},
                ]
            },
            {
                label: "Data Center", icon:"fa-duotone fa-camera-movie", visible() {
                    return  (aState.user.groups.DATA_CENTER_SETUP    == true)
                         || (aState.user.groups.DATA_CENTER_DOWNLOAD == true)
                },
                items: [
                    {label: "Setup",    icon: "fa-duotone fa-gears",       route: "datacenter-setup", visible() {
                        return  (aState.user.groups.DATA_CENTER_SETUP    == true)
                    }},
                    {label: "Download", icon: "fa-duotone fa-download",            route: "datacenter-download", visible () {
                        return  (aState.user.groups.DATA_CENTER_DOWNLOAD    == true)
                    }},
                ]
            },
        ])

        const isDesktop = () => {return window.innerWidth > 1024}
        const onWrapperClick = () => {
            if (!state.menuClick) {
                state.mobileMenuActive = false;
            }
            state.menuClick = false;
        }
        const onMenuToggle = (event) => {
            state.menuClick = true;
            if (isDesktop()) {
                state.staticMenuInactive = !state.staticMenuInactive;
            }
            else {
                state.mobileMenuActive = !state.mobileMenuActive;
            }
            event.preventDefault();
        }
        const onSidebarClick = () => {
            state.menuClick = true;
        }
        const isSidebarVisible = () => {
            if (isDesktop ()) {
                return !state.staticMenuInactive;
            } else {
                return true;
            }
        }
        const containerClass = computed (() => {
            return ["layout-wrapper", {
                "layout-static-sidebar-inactive": state.staticMenuInactive,
                "layout-mobile-sidebar-active":   state.mobileMenuActive,
            }];
        })
        const onMenuItemClick = (event) => {
            if (event.item && !event.item.items) {
                state.mobileMenuActive = false;
            }
        }
        onMounted (projects.loadProjects)
        return { state, menu
               , onWrapperClick, onMenuToggle, onSidebarClick, isSidebarVisible
               , onMenuItemClick, containerClass
               }
    },
    components: { AppTopBar, AppProfile,AppMenu,AppFooter, Toast}
}
</script>

<style lang="scss">
@import "./App.scss";
</style>
